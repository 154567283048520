var spotfire;
spotfire || (spotfire = {
        webPlayer: {}
    }), function () {
    class e extends Error {
        constructor(e, t, n) {
            super(n),
            this.code = t,
            this.category = e
        }
    }
    function t(t, o, ...r) {
        if (!n[t][o])
            return new e(t, o, o);
        let s = n && function (e, ...t) {
            return e.replace(/{([\d])}/g, ((e, n) => t[parseInt(n)]))
        }
        (n[t][o], ...r);
        return new e(t, o, s)
    }
    const n = {
        API_USAGE: {
            INVALID_SERVER: "Invalid server URL '{0}'.",
            INVALID_REDIRECT_URL: "Invalid domain. The redirect URL ('{0}') must match the domain of the current page ('{1}').",
            NON_SILENT_ANONYMOUS_FLOW: "Anonymous authentication must use silent mode.",
            INVALID_TOKEN: "Invalid authorization token.",
            MISSING_TOKEN: "Missing authorization token.",
            INVALID_PATH: "Invalid analysis path '{0}'.",
            INVALID_API_VERSION: "Invalid API version '{0}'.",
            SET_API_VERSION: "API version must be set once."
        },
        OAUTH: {
            ACCESS_DENIED: "The user or authorization server denied access to Spotfire.",
            INVALID_REQUEST: "The request is missing a parameter, contains an invalid parameter, includes a parameter more than once, or is otherwise invalid.",
            INVALID_SCOPE: "The requested scope is invalid or unknown.",
            SERVER_ERROR: "Server error.",
            TEMPORARILY_UNAVAILABLE: "The authorization server is temporarily unavailable.",
            UNAUTHORIZED_CLIENT: "The client is not allowed to request an authorization code.",
            UNSUPPORTED_RESPONSE_TYPE: "The server does not support obtaining an authorization code."
        },
        SERVER_CONFIGURATION: {
            ENDPOINT: "Failed to get OAuth endpoint.",
            HASH_ALGORITHM: "Hashing algorithm {0} is not configured on server {1}.",
            SCOPE: "Scope {0} is not configured on server {1}.",
            RESPONSE_TYPE: "OAuth response type {0} is not configured on server {1}."
        },
        INTERNAL_ERROR: {
            FAILED_TO_REFRESH_TOKEN: "Failed to refresh OAuth token. Reason: '{0}'.",
            INVALID_STATE: "Invalid OAuth state.",
            FAILED_TO_LOAD_API: "Failed to load the Spotfire JavaScript API version. Reason unknown."
        }
    };
    let o;
    try {
        o = JSON.parse(sessionStorage.getItem("sf-access-tokens") || "{}"),
        sessionStorage.removeItem("sf-access-tokens"),
        window.addEventListener("unload", (() => {
                Object.keys(o).length && sessionStorage.setItem("sf-access-tokens", JSON.stringify(o))
            }))
    } catch (e) {}
    let r = null,
    s = "",
    i = "";
    const a = {},
    c = {};
    async function p(e, n) {
        const r = f(e, n);
        let s = o[r];
        if (s) {
            if (!s.access_token || s.expires < Date.now() - 6e4) {
                try {
                    s = await I(s)
                } catch (e) {}
                if (!s || !s.access_token)
                    return null
            }
            if (await async function (e) {
                const {
                    promise: t,
                    resolve: n
                } = _(),
                o = new XMLHttpRequest,
                r = `${e.server}/spotfire/wp-token/js-api/validate-token`;
                return o.open("POST", r, !0),
                o.setRequestHeader("content-type", "application/x-www-form-urlencoded"),
                o.onload = s,
                o.onerror = s,
                o.send(`accessToken=${e.access_token}`),
                t;
                function s() {
                    n(200 == o.status)
                }
            }
                (s))
                return d(s), s;
            o[r] = void 0,
            delete o[r]
        }
        const i = sessionStorage.getItem("sf-oauth-result");
        if (!i)
            return null;
        sessionStorage.removeItem("sf-oauth-result");
        const {
            state: a,
            code: c,
            error: p
        } = JSON.parse(i);
        if (a && c)
            return u(a, c);
        if (p)
            throw t("OAUTH", p.toUpperCase());
        return null
    }
    function l(e, n, o, r, s) {
        let {
            promise: i,
            resolve: a,
            reject: c
        } = _(),
        p = new XMLHttpRequest;
        return p.open("GET", `${e}/spotfire/.well-known/oauth-authorization-server`),
        p.onload = async() => {
            const i = "api.js-api offline",
            l = "S256",
            u = "code";
            if (200 !== p.status)
                return void c(t("SERVER_CONFIGURATION", "ENDPOINT"));
            let d = JSON.parse(p.responseText);
            const I = i.split(" ").filter((e => !d.scopes_supported.includes(e)));
            if (I.length > 0)
                return void c(t("SERVER_CONFIGURATION", "SCOPE", I.join(","), e));
            if (!d.code_challenge_methods_supported.includes(l))
                return void c(t("SERVER_CONFIGURATION", "HASH_ALGORITHM", l, e));
            if (!d.response_types_supported.includes(u))
                return void c(t("SERVER_CONFIGURATION", "RESPONSE_TYPE", u, e));
            let _,
            f,
            E = d.authorization_endpoint,
            A = crypto.getRandomValues(new Uint32Array(2)).join("");
            "https:" === location.protocol && (_ = crypto.getRandomValues(new Uint32Array(5)).join(""), f = await async function (e) {
                const t = (new TextEncoder).encode(e),
                n = await crypto.subtle.digest("SHA-256", t),
                o = Array.from(new Uint8Array(n));
                return btoa(String.fromCharCode.apply(null, o)).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "")
            }
                (_));
            let R = [["response_type", u], ["client_id", n], ["scope", i], ["state", A], ["code_challenge_method", l], ["redirect_uri", encodeURIComponent(o)]];
            f && R.push(["code_challenge", f]),
            r && R.push(["prompt", "none"]),
            R.push(["anonymous", "" + !!s]);
            const h = {
                redirectUri: o,
                targetUrl: location.href,
                secret: _,
                tokenEndpoint: d.token_endpoint,
                revokeEndpoint: d.revocation_endpoint,
                server: e,
                clientId: n,
                silent: r
            };
            r || sessionStorage.setItem(A, JSON.stringify(h)),
            a({
                startUrl: `${E}?${R.map((e=>e.join("="))).join("&")}`,
                stateKey: A,
                state: h
            })
        },
        p.send(null),
        i
    }
    async function u(e, n) {
        let {
            targetUrl: r,
            secret: s,
            tokenEndpoint: i,
            revokeEndpoint: a,
            server: c,
            clientId: p,
            redirectUri: l
        } = e || {}, {
            promise: u,
            resolve: I,
            reject: E
        } = _();
        r && ("https:" !== location.protocol || s) || E(t("INTERNAL_ERROR", "INVALID_STATE"));
        var A = new XMLHttpRequest;
        A.open("POST", i, !0),
        A.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"),
        A.onload = async function () {
            if (200 === A.status) {
                var e = JSON.parse(A.responseText);
                e = {
                    ...e,
                    server: c,
                    clientId: p,
                    tokenEndpoint: i,
                    revokeEndpoint: a,
                    expires: Date.now() + 1e3 * parseInt(e.expires_in)
                },
                o[f(c, p)] = e,
                d(e),
                I(e)
            } else
                console.error("Failed to request oauth access token", A.response), E(t("OAUTH", "SERVER_ERROR"))
        };
        let R = [["grant_type", "authorization_code"], ["code", encodeURIComponent(n)], ["redirect_uri", encodeURIComponent(l)], ["client_id", p]];
        return "https:" === location.protocol && R.push(["code_verifier", s]),
        A.send(R.map((e => e.join("="))).join("&")),
        u
    }
    async function d(e) {
        if (!e.refresh_token)
            return;
        const t = f(e.server, e.clientId);
        if (a[t])
            return;
        a[t] = !0;
        let n = !e.access_token || e.expires < Date.now() ? 0 : Math.max(0, e.expires - Date.now() - 6e4);
        for (; await E(n), o[t]; ) {
            const o = (e = await I(e)).access_token;
            n = Math.max(0, e.expires - Date.now() - 6e4),
            c[t] && c[t].forEach((e => e(o)))
        }
    }
    async function I(e) {
        const {
            promise: n,
            resolve: r,
            reject: s
        } = _(),
        i = new XMLHttpRequest;
        i.open("POST", e.tokenEndpoint, !0),
        i.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"),
        i.onload = c,
        i.onerror = c;
        const a = [["grant_type", "refresh_token"], ["refresh_token", e.refresh_token], ["client_id", e.clientId]];
        return i.send(a.map((e => e.join("="))).join("&")),
        n;
        function c() {
            if (200 === i.status) {
                const t = {
                    ...e,
                    ...JSON.parse(i.responseText)
                };
                t.expires = Date.now() + 1e3 * parseInt(t.expires_in),
                o[f(e.server, e.clientId)] = t,
                function (t) {
                    const {
                        promise: n,
                        resolve: o
                    } = _(),
                    r = new XMLHttpRequest;
                    return r.open("POST", `${e.server}/spotfire/wp-token/js-api/switch-access-token`, !0),
                    r.setRequestHeader("Content-Type", "application/json"),
                    r.setRequestHeader("Authorization", "Bearer " + e.access_token),
                    r.onload = () => o(t),
                    r.onerror = () => o(t),
                    r.send(JSON.stringify({
                            newAccessToken: t.access_token
                        })),
                    n
                }
                (t).then(r)
            } else {
                const e = JSON.parse(i.responseText || "{}");
                s(t("INTERNAL_ERROR", "FAILED_TO_REFRESH_TOKEN", e.error_description))
            }
        }
    }
    function _() {
        let e,
        t;
        return {
            promise: new Promise(((n, o) => {
                    e = n,
                    t = o
                })),
            resolve: e,
            reject: t
        }
    }
    function f(e, t) {
        return e + "|" + t
    }
    function E(e) {
        return new Promise((t => setTimeout(t, e)))
    }
    spotfire.webPlayer.setApiVersion = function (e) {
        if (s && s != e)
            throw t("API_USAGE", "SET_API_VERSION");
        const n = e.match(/^(\d*).(\d*)$/);
        if (!n || parseInt(n[1]) < 12 || 12 == parseInt(n[1]) && parseInt(n[2]) < 0)
            throw t("API_USAGE", "INVALID_API_VERSION", e);
        s = e
    },
    spotfire.webPlayer.oauthEnd = function () {
        const e = new URLSearchParams(location.search),
        t = e.get("state"),
        n = e.get("code"),
        o = e.get("error");
        if (!t)
            return !1;
        if (window.parent !== window)
            window.parent.postMessage({
                stateKey: t,
                code: n,
                error: o
            }, location.origin);
        else {
            const e = JSON.parse(sessionStorage.getItem(t));
            sessionStorage.removeItem(t),
            sessionStorage.setItem("sf-oauth-result", JSON.stringify({
                    state: e,
                    code: n,
                    error: o
                })),
            location.replace(e.targetUrl)
        }
        return !0
    },
    spotfire.webPlayer.authenticate = async function (e) {
        let {
            server: n,
            clientId: o,
            redirectUri: r,
            silent: s,
            anonymous: i
        } = e || {};
        const a = function (e) {
            try {
                (e = new URL(e).origin).startsWith("http") || (e = null)
            } catch (t) {
                e = null
            }
            return e
        }
        (n);
        if (!a)
            throw t("API_USAGE", "INVALID_SERVER", n);
        if (n = a, i && !s)
            throw t("API_USAGE", "NON_SILENT_ANONYMOUS_FLOW");
        if (location.origin !== new URL(r).origin)
            throw t("API_USAGE", "INVALID_REDIRECT_URL", new URL(r).origin, location.origin);
        if (!document.body) {
            const {
                promise: t,
                resolve: n,
                reject: o
            } = _();
            return document.addEventListener("DOMContentLoaded", (() => spotfire.webPlayer.authenticate(e).then(n).catch(o))),
            t
        }
        const c = await p(n, o);
        if (c)
            return c;
        if (s)
            try {
                return await async function (e, t, n, o) {
                    let {
                        promise: r,
                        resolve: s,
                        reject: i
                    } = _();
                    const a = document.createElement("iframe");
                    a.style.display = "none";
                    let c = setTimeout(E, 3e4);
                    window.addEventListener("message", A),
                    document.body.appendChild(a);
                    let {
                        startUrl: p,
                        stateKey: d,
                        state: I
                    } = await l(e, t, n, !0, o);
                    async function f({
                        stateKey: e,
                        code: t,
                        error: n
                    }) {
                        window.removeEventListener("message", A),
                        clearTimeout(c),
                        document.body.removeChild(a),
                        n || d !== e ? i(n) : s(await u(I, t))
                    }
                    function E() {
                        f({
                            error: "Timeout",
                            stateKey: null,
                            code: null
                        })
                    }
                    function A(e) {
                        e.source === a.contentWindow && e.origin === location.origin && f(e.data)
                    }
                    return a.src = p,
                    r
                }
                (n, o, r, i),
                await p(n, o)
            } catch (e) {
                if (i)
                    throw e
            }
        return location.replace((await l(n, o, r, !1, i)).startUrl),
        new Promise((() => {}))
    },
    spotfire.webPlayer.connect = async function (n) {
        const {
            token: a,
            path: p
        } = n, {
            promise: l,
            resolve: u,
            reject: d
        } = _();
        return "string" == typeof p && p || d(t("API_USAGE", "INVALID_PATH", p)),
        a || d(t("API_USAGE", "MissingToken")),
        a.access_token && a.server || d(t("API_USAGE", "InvalidToken")),
        async function (n) {
            const {
                promise: o,
                resolve: a,
                reject: c
            } = _();
            s || c(t("API_USAGE", "SET_API_VERSION"));
            if (i)
                return "function" == typeof spotfire.webPlayer.Application ? a(n) : (r.addEventListener("load", u), r.addEventListener("error", l)), o;
            async function p() {
                const {
                    promise: o,
                    resolve: r,
                    reject: i
                } = _(),
                a = `${n.server}/spotfire/wp-token/js-api/locate-js-api/${s}`,
                c = new XMLHttpRequest;
                return c.open("GET", a),
                c.onload = p,
                c.onerror = () => i(t("INTERNAL_ERROR", "FAILED_TO_LOAD_API")),
                c.send(null),
                o;
                function p() {
                    const t = JSON.parse(c.responseText);
                    t.error ? i(new e("SERVER_CONFIGURATION", "API_NOT_LOADED", t.error.description)) : r(t.uri)
                }
            }
            function l() {
                c(t("API_USAGE", "INVALID_API_VERSION", s))
            }
            function u() {
                "function" == typeof spotfire.webPlayer.Application ? a(n) : c(t("INTERNAL_ERROR", "FAILED_TO_LOAD_API"))
            }
            return p().then((e => {
                    i = s,
                    r = document.createElement("script"),
                    r.src = `${n.server}${e}`,
                    r.addEventListener("load", u),
                    r.addEventListener("error", l),
                    document.head.appendChild(r)
                })).catch(c),
            o
        }
        (a).then((function (e) {
                const t = f(e.server, e.clientId),
                r = o[t] || e,
                s = new spotfire.webPlayer.Application(e.server, n.customization, p, n.openParameters, n.reconnect, function (e) {
                    const t = f(e.server, e.clientId);
                    c[t] || (c[t] = []);
                    return n => {
                        c[t].push(n),
                        n(e.access_token)
                    }
                }
                        (r));
                return s.onLoggedOut((() => function (e) {
                        const t = o[e];
                        if (!t)
                            return;
                        function n(e, n) {
                            const {
                                promise: o,
                                resolve: r,
                                reject: s
                            } = _();
                            if (e) {
                                const o = new XMLHttpRequest;
                                o.open("POST", t.revokeEndpoint, !0),
                                o.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"),
                                o.onload = r,
                                o.onerror = s;
                                const i = [["token_type_hint", n], ["token", e], ["code", t.access_token], ["client_id", t.clientId]];
                                o.send(i.map((e => e.join("="))).join("&"))
                            } else
                                r();
                            return o
                        }
                        o[e] = void 0,
                        delete o[e],
                        n(t.refresh_token, "refresh_token").then((() => n(t.access_token, "access_token")))
                    }
                        (t))),
                s
            })).then(u).catch(d),
        l
    }
}
();
